.container {
  margin: 10px;
}



.flexContainer {
  display: flex;
  flex-direction: row;
}

/*.center {
  margin: auto;
}

.gridContainer1Columns {
  display: inline-grid;
  grid-template-columns: auto;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;*/
  /* margin: auto; Center horizontally */

/*}*/

.gridContainerSingleRowLayoutSpaced {
  display: inline-grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto;
  padding: 5px;
  /* margin: auto; */
}

/*.gridContainer4 {
  display: inline-grid;
  grid-template-columns: auto auto auto auto;
  margin: auto;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px !;*/
  /* font-size: 30px!; */
  /*text-align: center;

}

.grid-itemTitle {
  background-color: rgba(255, 255, 255, 0.8);*/
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  /*text-align: center;
  padding: 20px !;
  font-size: 14px;
  margin-left: 10px;
  width: 80px;


}


.grid-itemClear {
  background-color: rgba(255, 255, 255, 0.8);*/
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  /*text-align: center;
  margin-left: 10px;
  font-size: 30px !;*/
  /* width: 110px; */


/*}


.table {
  display: table;
}

.row {
  display: table-row;
}

.cell {
  margin: 0px;
  padding-top: 0px;
  display: table-cell;*/
  /* padding: 1px;
    border: 1px solid #000;
    font-size: small; */
/*}


.cellDateInput {
  margin: 0px;
  padding-top: 5px;
  display: table-cell;
  padding-right: 8px;

}*/

/*.gridContainerSingleRow {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(auto, auto));
  margin: auto;
}*/

.fullScreenContainer {
  position: absolute;
  top: 33.33vh;
  /* 1/3 of the screen height */
  left: 50vw;
  /* Center horizontally */
  transform: translate(-50%, -33.33%);
  /* 
    The value -50% will move the element left by 50% of its own width, 
    and -33.33% up by 33.33% of its own height. */
}

/* //hardcode for mobile */
@media screen and (max-width: 1024px) {
  .fullScreenContainer {
    position: absolute;
    top: 33.33vh;
    /* 1/3 of the screen height */
    left: 50vw;
    /* Center horizontally */
    transform: translate(-50%, -33.33%);
    /* 
        The value -50% will move the element left by 50% of its own width, 
        and -33.33% up by 33.33% of its own height. */
    width: 90vw;
    /* to force content make use of horizontal space */

  }
}


.logoWidth {
  width: 40vw;
  max-width: 64px;
}


.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f8f8;
}

.logo {
  display: flex;
  align-items: center;

}

.title {
  margin-left: 10px;
}